import * as React  from 'react';
import {AppBar,Toolbar,Typography,Link,Select,MenuItem,FormControl,InputLabel} from '@mui/material'; 
import {} from 'react-router-dom';
import * as locales from '@mui/material/locale';
import { useTheme } from '@emotion/react';
const Header =()=>{
  const theme = useTheme();
  const [locale, setLocale] = React.useState('zhCN');
   return ( <>
    <AppBar position="static" color="primary">
      <Toolbar>
        <Link href="/"><Typography variant='h5' sx={{color:"white"}}>{theme.cusLocale.data["workshop"]}</Typography></Link> 
        </Toolbar>
    </AppBar> 
  </>);
}

export default Header;