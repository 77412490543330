import { createBrowserRouter } from "react-router-dom";

import ErrorPage from './Page/ErrorPage';
import LoginPage from './Page/LoginPage';
import RechargePage from './Page/RechargePage';
import OrderPage from './Page/OrderPage';
 
import Help from "./Page/Help";
import {Layout as MainLayout} from './layout/layout';

const router = createBrowserRouter([
    {
        path:"/",
        element:<MainLayout/>,
        errorElement:<ErrorPage/>,
        children:[
            {index:true,element:<LoginPage/>},
            {
                path:"/order",
                element:<RechargePage/>
            }, 
            {
                path:"/order/detail",
                element:<OrderPage/>,
            },
            {
                path:"/help",
                element:<Help/>,
            }
        ]
    }
]);

export default router;