import * as React from 'react'

function useThrottle(fn, delay, dep = []) {
    const { current } = React.useRef({ fn, timer: null })
    React.useEffect(
      function () {
        current.fn = fn
      },
      [fn]
    )
  
    return React.useCallback(function f(...args) {
      if (!current.timer) {
        current.timer = setTimeout(() => {
          delete current.timer
          current.fn.call(this, ...args)
        }, delay)
       
      }
    }, dep)
}

export default useThrottle;