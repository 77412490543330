import { Typography ,Box,Container,Grid,Avatar,Paper,Link} from "@mui/material";
import Help1 from '../asset/help1.png';
import Help2 from '../asset/help2.png';
import steam2 from '../asset/steam2.png';
import {useTheme, styled } from '@mui/material/styles';
const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2), 
    color: theme.palette.text.primary,
  }));
 
function Help(){
    const theme=useTheme();
    return (
        <Box sx={{ px: 3,justifyItems:"center"}}>
        <StyledPaper
          sx={{
            my: 1,
            mx: 'auto',
            p: 2,
          }}
        >
        <Typography variant="h4">{theme.cusLocale.data["methodone"]}<Link href="/">({theme.cusLocale.data["goBack"]})</Link></Typography>
          <Grid container   direction="column" spacing={2}>
            <Grid item>
             <Typography variant="body">{theme.cusLocale.data["step1"]}</Typography>
            </Grid>
            <Grid item >
              <img src={Help1} style={{width:1024}}></img>
            </Grid>
            <Grid item>
             <Typography variant="body">{theme.cusLocale.data["step2"]}</Typography>
            </Grid>
            <Grid item >
              <img src={Help2} style={{width:1024}}></img>
            </Grid>
          </Grid>
        </StyledPaper>
        <StyledPaper
          sx={{
            my: 1,
            mx: 'auto',
            p: 2,
          }}
        >
          <Grid container   direction="column" spacing={2}> 
            <Typography variant="h4">{theme.cusLocale.data["methodTwo"]}</Typography>
            <Grid itemc spacing={2}>
             <Typography variant="body">{theme.cusLocale.data["step3"]}</Typography>
            </Grid>
            <Grid item >
              <img src={steam2} style={{width:1024}}></img>
            </Grid>
          </Grid>
        </StyledPaper>
        
      </Box>
    )
}

export default Help;