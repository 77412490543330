function GetGameName(game){
    if (game == "aw"){
        return "atomicwar";
    }else if (game == "lw"){
        return "kkHero";
   }else if (game == "ym"){
        return "ymmj";
   }else if (game == "party"){
     return "dotaParty";
   }
   return "";
}

export default GetGameName;