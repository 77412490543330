import * as React from 'react';
import { styled } from '@mui/material/styles';
import Header from './header/header';
import {Footer} from './footer/footer';
import CssBaseline from '@mui/material/CssBaseline';
import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';

const LayoutRoot = styled('div')(()=>({
    display:'flex',
    flex:'1 1 auto',
    maxWidth:'100%', 
}));


const LayoutContainer = styled('div')({
    marginBottom:'20px',
    width:'100%',
});

export const Layout =(props)=>{
    const {children}=props; 

    return ( 
        <React.Fragment>
            <Header></Header>
            <CssBaseline/>
            <LayoutRoot>
                <LayoutContainer >
                    <Box 
                    component="main"
                    sx={{
                    display:'flex', 
                    justifyContent:'center',
                    alignItems:'center',
                    }}> 
                    <Outlet/>
                    </Box>
                </LayoutContainer>
            </LayoutRoot>
            <Footer></Footer> 
        </React.Fragment>
    );
}