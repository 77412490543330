import {Box,Typography,Link,Grid,Stack,ButtonBase, Container}from '@mui/material' 
import { grey } from '@mui/material/colors';  
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
const Item = styled('div')(({ theme }) => ({ 
  ...theme.typography.body2, 
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Footer=()=>{
  const theme = useTheme();
  const onLanguageClick=(lang)=>{
      console.log(lang);
      theme.cusLocale.callfn(lang);
  }
    return (
        <Box sx={{ bgcolor: grey[100], p:5,}} component="footer"> 
            <Grid container spacing={2}> 
              <Grid md={4} xs={12} sm={12} item>
                    <Typography  component="strong" >{theme.cusLocale.data["language"]}</Typography>
                    <Stack
                      direction={{ md:"row",xs: 'column', sm: 'column' }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      >
                        <ButtonBase component='a' sx={{ color:"gray",fontSize:"10px"}} onClick={()=>{onLanguageClick("zh-CN")}}>中文</ButtonBase>
                        <ButtonBase component='a' sx={{ color:"gray",fontSize:"10px"}} onClick={()=>{onLanguageClick("en-US")}}>English</ButtonBase>
                        <ButtonBase component='a' sx={{ color:"gray",fontSize:"10px"}} onClick={()=>{onLanguageClick("ru-RU")}}>Русский</ButtonBase>
                      </Stack>
                  </Grid>
                <Grid  md={4}  xs={12} sm={12} item>
                    <Typography
                      variant="subtitle1"  
                      component="p"
                  >
                      Copyright@{(new Date).getFullYear()} {theme.cusLocale.data["workshop"]}
                  </Typography>
                </Grid>
      
                <Grid md={4} xs={12} sm={12} item> 
                  <Container sx={{wordBreak:"break-all"}}>
                    <Link href="mailto:atomgame@126.com"  sx={{ color:"black"}} underline='none' variant="soft"  >
                    <span>{theme.cusLocale.data["email"]}</span><span>:atomgame@126.com</span>
                    </Link>
                    </Container>  
                </Grid>
            </Grid>
           
        </Box>
    );
} 
