import * as React from 'react'; 
import {Box,TextField,FormControl,InputLabel,Select,MenuItem,Button,Alert,Snackbar,Typography, Container} from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';
import {addGame,setSteamId,setPlayerName} from '../store/user';
import FetchData  from '../utils/fetch';
import GetUrl from '../utils/urls';
import {useNavigate ,Link} from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useTheme } from '@emotion/react';

export default function LoginPage() {
  const theme=useTheme(); 
  const userInfo = useSelector((state)=>state.user);  
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  React.useEffect(()=>{
    getPlayer();
  },[userInfo]);

  const getPlayer =()=>{
    if (!userInfo.steamId || userInfo.steamId.length!=17){
      return ;
    }
    if (userInfo.steamId.substring(0,4) != "7656"){
        return ;
    }
    let gameUrl = GetUrl(userInfo.game);
 
    let data={
      method:"GET",
      url:gameUrl.host + gameUrl.player(userInfo.steamId),
    }
    FetchData(data,(rvData)=>{
        if(rvData.Status && rvData.Status.Code == "1"){ 
          dispatch(setPlayerName({name:rvData.Data.Name}));
        }
    })
  }
  const onSteamIdChange=(event)=>{
      let v = event.target.value;
      if (v) {
        v = v.trim();
      } 
      if (v.length==17){
          if (v.substring(0,4) == "7656"){
            dispatch(setSteamId({steamId:v})); 
          }
        }
  }

  const onSelectChanged=(event)=>{ 
    dispatch(addGame({game:event.target.value}));
  }

  const onSubmit=()=>{ 
    if (userInfo.steamId){
      navigate("/order");
    } else{
      setState({ ...state, open: true });
    }
  }

  const handleClose = () => { 
    setState({ ...state, open: false });
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
        marginTop:'100px',
      }}
      noValidate
      autoComplete="on"
    >
        <Typography variant='h4'>{theme.cusLocale.data["login"]}</Typography>
        <Typography>sigin</Typography>
        <FormControl fullWidth >
                <TextField fullWidth  id="steamId" label="SteamId" variant="outlined" onChange={onSteamIdChange}/>
                {userInfo.steamId && <Alert  sx={{py:0,m:1,fontSize:'0.7em',}}severity="success">SteamId:<strong>{userInfo.steamId}</strong>, {theme.cusLocale.data["name"]}:<strong>{userInfo.name}</strong></Alert>}
        </FormControl>
         
        <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="game-label"  
            inputProps={{
              autocomplete:"on",
            }}> {theme.cusLocale.data["selectGame"]}</InputLabel>
            <Select
                    labelId="game-label"
                    id="game"
                    value={userInfo.game}
                    label={theme.cusLocale.data["selectGame"]}
                    onChange={onSelectChanged}
            >
                <MenuItem value={"aw"}>{theme.cusLocale.data["atomicwar"]}</MenuItem>
                <MenuItem value={"ym"} disabled>{theme.cusLocale.data["ymmj"]}</MenuItem>
                <MenuItem value={"lw"} disabled>{theme.cusLocale.data["kkHero"]}</MenuItem>
                <MenuItem value={"party"}disabled>{theme.cusLocale.data["dotaParty"]}</MenuItem>
            </Select>
        </FormControl>
        <Link to="/help"><Typography variant="caption" color={grey[600]}>{theme.cusLocale.data["steamIdHelpText"]}?</Typography></Link>
        <Snackbar   anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert variant="filled" onClose={handleClose} severity="info" sx={{ width: '100%' }}>
             {theme.cusLocale.data["steamIdWarnText"]}
            </Alert>
        </Snackbar>
        <Box>
        <Button sx={{width:'100%'}} variant="contained" size="medium" onClick={onSubmit}>
        {theme.cusLocale.data["goToRecharge"]}
        </Button>
        </Box>
       
    </Box>
  );
}
