
import * as React from 'react'; 
import {Layout as MainLayout} from './layout/layout';
import Box from '@mui/material/Box'; 
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from './theme';
import router from './router';
import {RouterProvider } from 'react-router-dom';
 
const enLocalesData = require('../src/locales/en.json');
const zhLocalesData = require('../src/locales/zh.json');
const ruLocalesData = require('../src/locales/ru.json');

function createLocaleTheme(locale,setLocale){
  if (locale.indexOf('zh')>=0){
    return createTheme({
      data:zhLocalesData,
      callfn:setLocale,
      lan:locale,
    });
  }else if (locale.indexOf('ru') >=0){
    return createTheme({
      data:ruLocalesData,
      callfn:setLocale,
      lan:locale,
    });
  }

  return createTheme({ 
    data:enLocalesData,
    callfn:setLocale,
    lan:locale,
  });
}

const defaultLanguage = navigator.language;
const App=()=>{
  let language = navigator.language;
  const [locale,SetLocale]=React.useState(language);
  return (
    <ThemeProvider theme={()=>createLocaleTheme(locale,SetLocale)}>
        <RouterProvider  router={router} >
        </RouterProvider>
    </ThemeProvider>);
}

export default App;
