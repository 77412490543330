import {useState,useEffect} from 'react';
import {Box,Grid,Table,TableRow,TableCell,Typography,Paper,CircularProgress,Fade} from '@mui/material';
import GetUrl from '../utils/urls';
import { useSelector } from 'react-redux'; 
import { useSearchParams,Navigate, Link } from 'react-router-dom';
import GetGameName from '../utils/constants';
import FetchData from '../utils/fetch';
import {Button} from '@mui/material';
import { useTheme } from '@emotion/react';

const getPlatform=(platform)=>{
    if(platform==1){
        return '支付宝';
    }else if (platform == 2){
        return '微信';
    }else if (platform == 4){
        return 'paypal';
    }else if (platform){
        return "Other";
    }
}

const OrderPage=(props)=>{
    const {orderId}=props;
    const theme=useTheme();
    const userInfo = useSelector((state)=>state.user);
    const {game,steamId,name} = userInfo;
    const [order,setOrder]=useState({});
    let [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(()=>{
        let url = GetUrl(game); 
        let initReq = {
            method:"GET",
            url:url.host+ url.status(searchParams.get("id"))
        };
        FetchData(initReq,(data)=>{
            if (data.Status.Code == "1"){
                 setOrder(data.Data);
            }
        });
    },[orderId])
        return (
                <Grid container spacing={1}    
                 justifyContent="center"
                 sx={{ marginTop:'10px'}}
                >   
                    <Grid  item lg={4}>
                        <Paper>
                            <Typography variant="h3" sx={{mb:2}}>{theme.cusLocale.data["paymentDetail"]}</Typography>
                                <Table>
                                <TableRow>
                                        <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["game"]} </TableCell>
                                        <TableCell align="right">{theme.cusLocale.data[GetGameName(userInfo.game)]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{color:"gray"}}>SteamId</TableCell>
                                        <TableCell align="right">{steamId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["name"]}</TableCell>
                                        <TableCell align="right">{name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["orderNo"]}</TableCell>
                                        <TableCell align="right">{order&&order.orderId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["gamemoney"]}</TableCell>
                                        <TableCell align="right">{order&&order.cnt}</TableCell>
                                    </TableRow> 
                                    <TableRow>
                                        <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["usedPaymentMethod"]}</TableCell>
                                        <TableCell align="right">{getPlatform(order.platform)}</TableCell>
                                    </TableRow> 
                                </Table>
                              
                    </Paper> 
                     <Link to="/order" style={{textDecoration:"none"}} ><Button variant="contained" sx={{my:5}}>{theme.cusLocale.data["continueRecharge"]}</Button></Link>
                    </Grid> 
            </Grid>
           );
}

export default OrderPage;