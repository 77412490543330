

function FetchData(params,callback){
  //console.log(params);
    let initReq={
        mode: 'cors',  
        headers:{
              'Content-Type': 'application/json', 
              'Access-Control-Allow-Origin':"no-cors",
            } ,
        method:params.method,
    }
    if (params.method.toUpperCase() == "POST"){
        initReq.body = JSON.stringify(params.data);
    }
    try{ 
        fetch(params.url,initReq).then((resp)=>resp.json()).then(data=>{  
          callback(data);
        });
      }catch(e){
    }
}

export default FetchData;