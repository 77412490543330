import {createSlice} from '@reduxjs/toolkit';

let localData = localStorage.getItem("steamId");
let initialState = {
    steamId:"",
    name:"",
    game:"aw",
    paymentPlatform:"",
    orders:[],
    completeOrders:[],
}

initialState = Object.assign(initialState,{
    steamId:localData,
})

export const userSlice=createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        setSteamId:(state,action)=>{
            state.steamId = action.payload.steamId;
        },
        setPlayerName:(state,action)=>{
            state.name=action.payload.name;
        },
        setGame:(state,action)=>{
           state.game = action.payload.game;
        },
        addOrder:(state,action)=>{ 
            state.orders.push(action.payload);
        },
        addCompletedOrders:(state,action)=>{ 
            state.completeOrders.push(action.payload);
        },
        setPaymentPlatform(state,action){
            state.paymentPlatform = action.payload.paymentPlatform;
        }
    }
});

export const {setSteamId,addGame,addOrder,setPlayerName,addCompletedOrders,setPaymentPlatform}=userSlice.actions;
export default userSlice.reducer;