const AtomicWarUrl={
host:"https://game3.jyzdianjing.com/sr", 
//host:"https://test.atomicgaming.net/pay/sr", 
    player:(steamId)=>`/order/player/${steamId}`,
    recharge:"/order/order",
    status:(orderId)=>`/order/status/${orderId}`,
    wechat:(orderId)=>`/wechat/do/${orderId}`,
    alipay:(orderId)=>`/alipay/do/${orderId}`,
    paypal:(orderId)=>`/paypal/do/${orderId}`,
    payssion:(orderId,pmId)=>`/payssion/do/${orderId}?pmId=${pmId}`,
}

function GetUrl(game){
    let gameUrl = {};
    if (game == "aw"){
        gameUrl = AtomicWarUrl;
    }
    return gameUrl
}

export default GetUrl;